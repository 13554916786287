import React from 'react'
import Layout from '../../../components/layout'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import Header from '../../../sections/Header/Header'
import BlogRoll from '../../../components/BlogRoll/BlogRoll'
import headerImg from '../../../assets/Salona_Consulting_Zlatni_Rat.jpg'
import i18n from '../../../i18n'
import SEO from "../../../components/seo"

const BlogIndexPage = ({ t }) => {
  if (i18n.language !== 'pt') {
    i18n.changeLanguage('pt')
  }
  return (
    <Layout>

      <Header 
        image={headerImg}
        title={t('BLOG_TITLE')}
        subtitle={t('BLOG_SUBTITLE')}
        actionTitle={t('BLOG_LET_GO')}
        btnClass='btn-2'
        section={"posts"}
      />
      <SEO title={t("TITLE_BLOG")} metaT={t("META_BLOG")} />
      <BlogRoll />
    </Layout>
  )
}

BlogIndexPage.propTypes = {
  t: PropTypes.any
}

export default withTranslation()(BlogIndexPage)
